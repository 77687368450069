export const CHECK_COMPANY_ENDPOINT = '/customerverify/';
export const LOGIN_ENDPOINT = '/auth/';
export const TWOAUTH_ADMIN = '/auth2fa/';
export const GET_COMPLAINTS_ENDPOINT = '/allmycomplaints/';
export const GET_COMPLAINT_ENDPOINT = '/complaint/';
export const POST_COMPLAINT_ENDPOINT = '/newcomplaint/';
export const SEND_FILE_ENDPOINT = '/messagefile/';
export const SEND_COMPLAINT_MESSAGE = '/message/';
export const PUT_READ_COMPLAINT = `/`;
export const CHECK_COMPLAINT = '/authcomplaint/';
export const TWOAUTH_USER = '/auth2fausercomplaint/';
export const FACE_TO_FACE_REQUEST = '/in-person/';
export const CLOSE_COMPLAINT = '/closecomplaint/';