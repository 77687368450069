<template>

 <header>

   <v-app-bar
       color="primary"
       density="comfortable"
   >
     <v-container>
      <v-app-bar-title class="app-title">Canal de denuncias {{formattedCompanyName}} </v-app-bar-title>
     </v-container>
   </v-app-bar>

  </header>

</template>
<script>
export default {
  data() {
    return {
      company: ''
    };
  },
  computed: {
    formattedCompanyName() {
      if (!this.company) {return ''}
      return `| ${this.company.charAt(0).toUpperCase()}${this.company.slice(1)}`;
    }
  },
  mounted() {
    this.company = this.$route?.params?.company;
  },
  watch: {
    '$route.params.company'(newCompany) {
      this.company = newCompany;
    }
  },
};
</script>

<style scoped>
.app-title {
  color: white;
}
</style>
<script setup>
</script>