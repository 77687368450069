import { createStore, createLogger } from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

const token = localStorage.getItem('token') || null;
const user = JSON.parse(localStorage.getItem('user')) || null;

export default createStore({
    state: {
        isAdmin: false,
        token,
        user,
        twoAuthSmsSend: false,
        company: null,
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        setToken(state, token) {
            localStorage.setItem('token', token);
            state.token = token;
        },
        setLogout(state) {
            state.token = null;
            state.twoAuthSmsSend = false;
            state.user = null;
            localStorage.clear();
        },
        setTwoAuthSmsSend(state, status) {
          state.twoAuthSmsSend = status;
        },
        setIsAdmin( state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        setCompany( state, company) {
            state.company = company;
        }
    },
    actions: {

        forceLogout({ commit }) {
            commit('setLogout');
        },
        setTwoAuthSmsStatus({ commit }, status) {
            commit('setTwoAuthSmsSend', status);
        },
        saveUser( { commit }, user){
            commit('setUser', user);
            commit('setAuthentication', true);
            commit('setToken', user?.token);
            commit('setIsAdmin', user?.position === 'customer');
        },
        saveCompany( { commit }, company) {
            commit('setCompany', company);
        }
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        isLoggedIn(state) {
            return state.user != null;
        },
        isWaitingTwoAuth(state) {
            return state.twoAuthSmsSend;
        },
        getUser(state) {
            return state.user;
        },
        getIsAdmin(state) {
            return state.isAdmin;
        },
        getCompany(state) {
            return state.company;
        }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});