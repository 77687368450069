import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import { showToast } from "@/helpers/ShowToast";
import { errorMessages } from "@/config/messages";

const routes =  [
    {
        path: "/:company",
        name: `Canal de denuncias`,
        children: [
            {
                path: "",
                name: 'index',
                meta: {
                    title: 'Canal de denuncias'
                },
                component: () => import("@/pages/IndexView.vue")
            },
            {
                path: "registrar-denuncia",
                name: "registrar",
                title: 'Registrar',
                meta: {
                    requiresAuth: false,
                    title: 'Registrar denuncia'
                },
                alias: "registrar",
                component: () => import("@/pages/complaint/CompliantRegister.vue")
            },
            {
                path: '2fa/:hash',
                name: "2fa",
                meta: {
                    requiresAuth: false,
                    title: 'Verifica tu identidad'
                },
                beforeEnter: async (to, from, next) => {
                    return store.getters['isWaitingTwoAuth'] ? next() : next(`/${store.getters['getCompany']}`);
                },
                props: route => ({ phone: route.query.telephone }),
                component: () => import("@/pages/OTPView.vue")
            },
            {
                path: 'manage/:complaintID',
                name: 'userManage',
                meta: {
                    requiresAuth: true,
                    title: 'Gestionar denuncia | Canal denuncias'
                },
                alias: 'manage/:complaintID',
                component: () => import("@/pages/ComplaintManagement.vue")
            }
        ]
    },
    {
        path: '/admin',
        name: 'manager zone',
        children: [
            {
                path: "login",
                name: "login",
                meta: {
                    requiresAuth: false,
                    title: 'Iniciar sesión | Canal denuncias'
                },
                beforeEnter: async (to, from, next) => {
                    if (!store.getters['isLoggedIn']) {
                        next();
                    } else {
                        next({name: 'dashboard'});
                    }
                },
                component: () => import("@/pages/admin/AdminLoginView.vue")
            },
            {
                path: '2fa/:hash',
                name: "user2fa",
                meta: {
                    requiresAuth: false,
                    title: 'Verifica tu identidad'
                },
                beforeEnter: async (to, from, next) => {
                    if (store.getters['isWaitingTwoAuth']) {
                        next();
                    } else {
                        next({name: 'dashboard'});
                    }
                },
                props: route => ({ phone: route.query.telephone }),
                component: () => import("@/pages/OTPView.vue")
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                meta: {
                    requiresAuth: true,
                    title: 'Panel de administración | Canal denuncias',
                },
                alias: '/dashboard',
                component: () => import("@/pages/admin/AdminDashboard.vue")
            },
            {
                path: 'manage/:complaintID',
                name: 'Gestionar denuncia',
                meta: {
                    requiresAuth: true,
                    title: 'Gestionar denuncia | Canal denuncias'
                },
                alias: '/manage/:complaintID',
                component: () => import("@/pages/ComplaintManagement.vue")
            },
            {
                path: "remember-password",
                name: "Recordar contraseña | Canal de denuncias",
                alias: '/remember',
                meta: {
                    requiresAuth: false,
                    title: 'Recordar contraseña'
                },
                component: () => import("@/pages/admin/RememberPasswordView.vue")
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        name: '404',
        meta: {
            requiresAuth: false,
            title: 'Página no encontrada'
        },
        component: () => import("@/pages/404View.vue")
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

/**
 * Required for set meta title from the router
 */
router.beforeEach((to, from, next) => {
    const title = to.meta.title || 'Portal denuncias';
    if (title) {
        document.title = `${title}` ;
    }
    next();
});

/**
 * Required for redirect access from protected urls with isLoggedIn = false to login
 */
router.beforeEach(async (to) => {

    if (!store.getters["isLoggedIn"] && to.meta.requiresAuth && to.name !== 'login' && to.name !== '2fa') {
        return { name: 'login' };
    }

});

router.onError(() => {
    showToast(errorMessages.UNEXPECTED_ERROR, 'danger');
});
export default router;