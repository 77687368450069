import {createToast, clearToasts} from "mosha-vue-toastify";

/*
* type can be: default, info, warning, success, danger
* */
export function showToast(text, type = "default", timeout = 5000, onClose= null) {
    createToast(text,
        {
            hideProgressBar: false,
            showIcon: true,
            type: type,
            position: 'top-center',
            timeout,
            onClose,
        });
}
export function hideToast(){
    clearToasts();
}

/*  TOAST CONFIG CHEATSHEET: see more at https://github.com/szboynono/mosha-vue-toastify

*
  {
    "name": "type",
    "type": "'info', 'danger', 'warning', 'success', 'default'",
    "default": "'default'",
    "description": "Give the toast different styles and icons."
  },
  {
    "name": "timeout",
    "type": "number",
    "default": "5000",
    "description": "How many ms you want the toggle to close itself? Note: passing -1 to the timeout will stop the modal from closing."
  },
  {
    "name": "position",
    "type": "'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'",
    "default": "'top-right'",
    "description": "Where do you want the toast to appear?"
  },
  {
    "name": "showCloseButton",
    "type": "boolean",
    "default": "true",
    "description": "Do you wanna show the close button?"
  },
  {
    "name": "showIcon",
    "type": "boolean",
    "default": "false",
    "description": "Do you wanna show the icon?"
  },
  {
    "name": "transition",
    "type": "'bounce', 'zoom', 'slide'",
    "default": "'bounce'",
    "description": "Which animation do you want?"
  },
  {
    "name": "hideProgressBar",
    "type": "boolean",
    "default": "false",
    "description": "Do we wanna hide the fancy progress bar?"
  },
  {
    "name": "swipeClose",
    "type": "boolean",
    "default": "true",
    "description": "Allows the user swipe close the toast"
  },
  {
    "name": "toastBackgroundColor",
    "type": "string",
    "default": "default color",
    "description": "Customize the background color of the toast."
  },
  {
    "name": "onClose",
    "type": "function",
    "default": "N/A",
    "description": "This function will be called at the end of the toast's lifecycle"
  }

*/