import store from '@/store/index';
import * as api from '@/config/apiEndpoints';


class AuthService {

    constructor (axios, router) {
        this.axios = axios;
        this.router= router;
    }

    async login(mail, password) {

        const response = await this.axios.post( api.LOGIN_ENDPOINT, { payload : { mail, password } });

        if (response && !response.data.user.active2fa){

            await store.dispatch('saveUser', response.data.user);

            return true;

        } else if (response && response.data.user.active2fa && !response.data.user.passed2fa){

            await store.dispatch('setTwoAuthSmsStatus', true);
            this.router.push({path: `/admin/2fa/${response.data.user.smsid}`, query: { telephone: response.data.user.telephone}});
        }
        else if (response && response.data.user.active2fa && response.data.user.passed2fa){
            await store.dispatch('saveUser', response.data.user);
            this.router.push('/admin/dashboard');
            return true;
        }
        else {
            await store.dispatch('setTwoAuthSmsStatus', false);
            await store.dispatch('saveUser', null);

            return false;
        }
    }

    async twoAuthVerification (code, smsid, role) {

        const endpoint = role === 'admin' ? api.TWOAUTH_ADMIN : api.TWOAUTH_USER;

        const response = await this.axios.post( endpoint, { payload: { code, smsid } } );

        if (response && response.data.user.token) {

            await store.dispatch('saveUser', response.data.user);
            await store.dispatch('setTwoAuthSmsStatus', false);
            if (response.data.user.position === 'whistleblower'){
                this.router.push(`/${store.getters['getCompany']}/manage/${response.data.user.complaint}`);
            }else {
                this.router.push('/admin/dashboard');
                return true;
            }

        }
        else {

            await store.dispatch('saveUser', null);
            await store.dispatch('setTwoAuthSmsStatus', false);
            return false;
        }
    }

    async checkComplaint(complaint, telephone) {

        const response = await this.axios.post(api.CHECK_COMPLAINT, { payload: { complaint, telephone}});

        if (response && response.data.user.active2fa && !response.data.user.passed2fa){

            await store.dispatch('setTwoAuthSmsStatus', true);
            this.router.push({path: `/${store.getters['getCompany']}/2fa/${response.data.user.smsid}`, query: { telephone: response.data.user.telephone}});

        }else if (response && response.data.user.token){

            await store.dispatch('saveUser', response.data.user);
            this.router.push({path: `/${store.getters['getCompany']}/manage/${complaint}`});

            return true;
        }
    }

    getToken() {
        return store.getters.getToken;
    }

    async doLogout() {

        this.router.push({ name: 'login'});

        await store.dispatch('forceLogout');
    }

}

export default AuthService;
